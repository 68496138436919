'use client'
import styled from "styled-components";
import {Black} from "@/styles/globalStyleVars";
import {Col, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({text,color,border}) => {

    return (
        <StyledComponent className={"section-title"} color={color} border={border}>
            <Row>
                <Col md={12}>
                    <h5>{reactHtmlParser(text)}</h5>
                </Col>
            </Row>

        </StyledComponent>
    );

};

const StyledComponent = styled.section`
    h5{
        font-size: 14px;
        line-height: 20px;
        font-weight: 800;
        color: ${props => props.color || "#3C3C3B"};
        border-bottom: 1px solid ${props => props.border || "#C6C6C6"};
        padding-bottom: 20px;
        margin-bottom: 40px;
        text-transform: capitalize;
    }
    .col-md-12{
       
    }
`;

export default MyComponent;
